import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function LoginLogList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "employee_name", sortable: false, label: "Employee Name" },
        { key: "email", sortable: false, label: "Email" },
        { key: "date", sortable: false, label: "Date" },
        { key: "time", sortable: false, label: "Time" },
        { key: "device_type", sortable: false, label: "Device Type" },
    ];
    const perPage = ref(10);
    const totalLoginLogList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const loginLogsMetaData = ref([]);
    const loginLogStatusOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalLoginLogList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData();
    });
    const fetchLoginLogsList = (ctx,callback) => {
        store
          .dispatch("app-login-logs/fetchLoginLogs", {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value,
          })
          .then((response) => {
            const login_log = response.data.data;
            const { total } = response.data.meta;
            loginLogStatusOption.value = response.data.status;
      
            // Check if callback is a function before calling it
            if (typeof callback === "function") {
              callback(login_log);
            }
      
            totalLoginLogList.value = total;
            loginLogsMetaData.value = login_log;
          })
          .catch((error) => {
            console.error(error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching login-logs",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      };
      

    return {
        fetchLoginLogsList,
        tableColumns,
        perPage,
        currentPage,
        totalLoginLogList,
        loginLogsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        loginLogStatusOption,
        statusFilter
    };
}
